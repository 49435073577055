<template>
  <router-view />
  <GlobalFloatingIcon v-if="state.is_MQ_Service == true" />
</template>

<script setup>
import { useStore } from "vuex";
const { state } = useStore();

// 电脑上打开H5，自动跳转到PC地址
// window.addEventListener("resize", function () {
//   if (window.innerWidth > 800) {
//     if (state.is_WEB == true) {
//       if (state.is_D == true) {
//         // G站的域名
//         // https://mysshopvipb.net/web/#/
//         // https://mysshopvipd.net/web/#/
//         window.location.href = "https://mysshopvipb.net/web/#/";
//         // window.location.href = "https://mall.mysshopvipb.net/web/#/";
//       }
//     }
//     console.log("resize");
//   }
// });
</script>

<style lang="less">
@import "./assets/css/base.css";
</style>
